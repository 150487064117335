@use "ds";
@use "tokens";
@use "mixins";

.backButton {
  @include mixins.default-layout;

  width: 100%;
  padding: 0 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 0 32px;
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    max-width: 1242px;
  }
}
