@use "ds";
@use "tokens";

.faqChoiceAgentItem {
  flex-shrink: 0;
  width: 329px;
  background-color: tokens.$color_bg_surface_tertiary;
  padding: 16px;
  border-radius: tokens.$radius_xlarge;
  display: flex;
  flex-direction: column;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    width: 439px;
    padding: 32px;
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    width: 100%;
    padding-bottom: 48px;
  }

  .description {
    margin-top: 8px;
    flex: 1 0 auto;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      margin-top: 12px;
    }
  }
}

.picture {
  margin-top: 20px;
  border-radius: tokens.$radius_xlarge;
  overflow: hidden;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    align-self: center;
    margin-top: 44px;
  }
}
