@use "ds";
@use "tokens";

.container {
  padding: 20px 24px 0;
  position: relative;

  &:not(:last-child) {
    .inner {
      border-bottom: 0.3px solid tokens.$color_border_surface;
    }
  }
}

.personalData {
  display: flex;
  align-items: center;
  column-gap: 32px;
  width: 100%;
}

.inner {
  padding-bottom: 24px;
  display: grid;
  column-gap: 20px;
  align-items: center;
  grid-template-columns: minmax(204px, 382px) minmax(130px, 180px) minmax(52px, 106px) minmax(85px, 106px) 166px;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    column-gap: 32px;
    grid-template-columns: 280px minmax(262px, 476px) 50px 90px 166px;
  }
}

.avatar {
  border-radius: tokens.$radius_large;
  flex-shrink: 0;
  overflow: hidden;
}

.info {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  width: 100%;
}

.button {
  border-radius: tokens.$radius_medium;
  overflow: hidden;
}
