@use "tokens";

.achievementsMobile {
  display: grid;
  grid-template-columns: 40px 1fr auto;
  column-gap: 12px;
  align-items: center;
  justify-items: flex-start;
  background-color: tokens.$color_bg_surface_tertiary;
  min-height: 56px;
  padding: 8px 12px;
  border-radius: tokens.$radius_large;
  margin-top: 8px;

  .title {
    color: tokens.$color_text_primary;
    text-align: left;
  }

  .count {
    color: tokens.$color_text_secondary;
  }

  .arrowIcon {
    transform: rotate(-90deg);
    color: tokens.$color_icon_secondary_default;
  }
}
