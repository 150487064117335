@use "ds";
@use "tokens";

.container {
  background: tokens.$color_bg_surface_primary;
  border-bottom-left-radius: tokens.$radius_xlarge;
  border-bottom-right-radius: tokens.$radius_xlarge;
  padding: 0 16px 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 0 0 24px;
  }
}

.controlsContainer {
  margin-top: 16px;
  display: flex;
  gap: 8px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    margin-top: 24px;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: auto 300px 1fr;
    padding: 0 24px;
  }
}
