@use "tokens";
@use "ds";

.breadcrumbs {
  padding: 8px 16px;
  color: tokens.$color_text_secondary;
  display: flex;
  flex-wrap: wrap;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 24px 0;
  }
}

.item {
  display: inline-flex;
  align-items: center;
  column-gap: 8px;

  &:not(:last-child) {
    &::after {
      content: "";
      flex-shrink: 0;
      margin-right: 8px;
      width: 6px;
      height: 8px;
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2599_15910)'%3E%3Cpath d='M1 -1L5 4L1 9' stroke='%23727B82' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2599_15910'%3E%3Crect width='6' height='8' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
    }
  }
}

.link {
  text-decoration: none;
  color: tokens.$color_text_secondary;
}

.separator {
  margin: 2px 3px 0;
}
