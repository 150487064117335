@use "ds";

.container {
  padding: 24px 0;
}

.header {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 0 24px;
}

.title {
  max-width: 300px;
}

.controls {
  display: grid;
  grid-template-columns: 1fr 56px;
  column-gap: 8px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    grid-template-columns: 257px 1fr;
  }
}
