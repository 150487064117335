@use "ds";
@use "tokens";

.container {
  padding: 16px;
  background: tokens.$color_bg_surface_primary;
  min-height: 414px;
  border-radius: tokens.$radius_xlarge;
  display: flex;
  align-items: center;

  div {
    width: 100%;
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    padding: 24px;
    min-height: 572px;
  }
}

.webViewButton {
  margin-top: auto;
  width: 100%;

  button {
    width: 100%;
  }
}
