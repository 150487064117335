@use "ds";
@use "tokens";

.container {
  position: relative;
  width: 100%;
  height: 250px;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    height: 300px;
  }
}

.iframe {
  border: 0;
  border-radius: tokens.$radius_medium;
}

.mapLoader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(240, 242, 245, 1);
  border-radius: tokens.$radius_medium;
}
