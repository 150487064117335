@use "ds";
@use "tokens";

.agentAgencyInfoItem {
  display: flex;
  column-gap: 4px;
  align-items: center;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
  }

  .subtitle {
    color: tokens.$color_text_secondary;
  }
}
