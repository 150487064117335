@use "ds";
@use "tokens";

.reviewCards {
  margin-top: 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    margin-top: 20px;
  }
}

.reviewCard {
  &:not(:last-child) {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 0.3px solid tokens.$color_bg_control_primary_selected;
  }
}
