@use "ds";

.container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 24px;
    row-gap: 24px;
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    padding: 24px 0;
  }
}
