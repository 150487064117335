@use "ds";
@use "typography";

.container {
  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 0 24px;
  }

  .title {
    margin-top: 20px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_promo_small;

      margin: 0;
    }
  }
}
