@use "ds";
@use "typography";

.agentsTitle {
  padding: 0 16px;
  margin-top: 20px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 0 24px;
    margin-top: 0;
  }

  .title {
    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_promo_small;
    }
  }
}
