@use "ds";
@use "tokens";

.container {
  border-top-right-radius: tokens.$radius_none;
  border-top-left-radius: tokens.$radius_none;
}

.top {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.avatar {
  width: 68px;
  height: 68px;
  flex-shrink: 0;
  border-radius: tokens.$radius_large;
  overflow: hidden;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
}

.progressWrapper {
  display: flex;
  column-gap: 8px;
  margin-top: 16px;
}
