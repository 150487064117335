@use "ds";
@use "tokens";

p.text {
  margin-top: 16px;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    margin-top: 24px;
  }
}
