@use "ds";
@use "mixins";
@use "tokens";
@use "typography";

.link {
  text-decoration: none;
  display: block;
  padding: 0 16px;
  height: 117px;

  &:not(:last-child) {
    border-bottom: 0.3px solid tokens.$color_border_surface;
  }

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 0 24px;

    &:not(:last-child) {
      border: 0;

      .inner {
        border-bottom: 0.3px solid tokens.$color_border_surface;
      }
    }
  }
}

.inner {
  display: grid;
  grid-template-columns: 68px 1fr;
  column-gap: 20px;
  align-items: center;
  padding: 16px 0;
  min-height: 99px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    grid-template-columns: 56px 1fr;
    padding: 20px 0;
  }
}

.avatar {
  position: relative;
  width: 68px;
  height: 68px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    width: 58px;
    height: 58px;
  }
}

.mainContentContainer {
  display: block;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: grid;
    grid-template-columns: 180px minmax(0, 516px) minmax(0, 100px) minmax(0, 116px);
    align-items: center;
    column-gap: 16px;
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    grid-template-columns: 180px minmax(0, 516px) minmax(0, 100px) minmax(0, 116px);
  }
}

.agentInfo {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    grid-column: 1;
  }
}

.title {
  display: flex;
  column-gap: 8px;
}

.mobileOffersCount {
  display: block;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: none;
  }
}

.desktopOffersCount {
  display: none;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: block;
  }
}

.dealsCount {
  display: block;
}

.companyText {
  margin-top: 4px;
}

.registrationDate {
  margin-top: 4px;
}

.rating {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  margin-top: 6px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    grid-column: 2;
    grid-row: 1 / 3;
    align-items: flex-start;
    flex-direction: column;
    column-gap: 6px;
    margin: 0;
  }
}

.inWork {
  margin-top: 6px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin: 0;
    width: 116px;
  }
}

.inWorkPrimary {
  display: none;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: block;
    padding-bottom: 2px;
  }
}

.complete {
  display: none;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 100%;
  }
}

.completePrimary {
  display: none;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: block;
  }
}

.pagination {
  display: flex;
  column-gap: 8px;
  width: fit-content;
  align-items: center;
  margin: 0 auto;
}

.button {
  padding-bottom: 16px;
}
