.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.title {
  max-width: 39%;
}

.texts {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.text {
  max-width: 100%;

  &.isSmall {
    max-width: 78%;
  }
}
