.container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.header {
  display: grid;
  grid-template-columns: 1fr 56px;
  column-gap: 8px;
}

.lines {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 4px;
  margin-top: 12px;
}

.line {
  max-width: 198px;

  &.isSmall {
    max-width: 130px;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.texts {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
