@use "ds";
@use "tokens";

.container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    row-gap: 16px;
  }
}

.pagination {
  display: flex;
  column-gap: 8px;
  width: fit-content;
  align-items: center;
  margin: 0 auto;
}
