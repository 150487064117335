@use "ds";
@use "tokens";
@use "mixins";
@use "typography";

.agencyOffices {
  padding: 16px 0;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 24px 0;
  }

  .title {
    padding: 0 16px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      padding: 0 24px;
    }

    @media #{ds.$dc-mq-tablet-landscape-and-more} {
      @include typography.font_heading_small;

      padding: 0 24px;
    }
  }
}
