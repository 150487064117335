@use "ds";
@use "mixins";
@use "tokens";

.agentInfoWork {
  display: grid;
  align-items: flex-start;
  grid-template:
    "company avatar"
    "position avatar" / 1fr 40px;
  column-gap: 12px;
  padding: 12px;
  border-radius: tokens.$radius_large;
  background-color: tokens.$color_bg_surface_tertiary;
  grid-column: 1 / -1;
  margin-top: 20px;

  &.onlyText {
    display: block;
  }

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    grid-column: 2;
    margin-top: 12px;
    padding: 0;
    align-self: flex-start;
    background-color: transparent;
    grid-template:
      "avatar company"
      "avatar position" / 40px 1fr;
  }

  .company {
    grid-area: company;
    width: fit-content;
    font-weight: 500;

    &.isText {
      cursor: default;
      color: tokens.$color_text_secondary;

      span {
        color: inherit;
      }
    }

    span {
      font-weight: inherit;
    }
  }

  .position {
    grid-area: position;
    color: tokens.$color_text_secondary;
  }
}

.avatar {
  grid-area: avatar;
  height: 40px;
}
