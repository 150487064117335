@use "ds";
@use "typography";

.agentMap {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    row-gap: 16px;

    .title {
      @include typography.font_heading_small;
    }
  }
}
