@use "tokens";

.container {
  display: grid;
  grid-template-columns: 147px minmax(0, 1fr) 150px;
  border-radius: tokens.$radius_xlarge;
  column-gap: 32px;
  align-items: center;
}

.avatar {
  width: 100%;
  flex-shrink: 0;
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.title {
  max-width: 93%;
}

.subTitle {
  max-width: 67%;
}

.achievements {
  display: flex;
  column-gap: 32px;
}

.achievement {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 120px;
}

.achievementLine {
  width: 100%;
}

.achievementSmallLine {
  max-width: 71%;
}

.status {
  width: 100%;
  max-width: 150px;
  align-self: flex-start;
  transform: translateY(calc(100% - 2px));
}
