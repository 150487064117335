@use "ds";
@use "mixins";
@use "tokens";
@use "typography";

.container {
  background: tokens.$color_bg_surface_primary;
  border-radius: tokens.$radius_xlarge;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    row-gap: 16px;
    padding: 24px;
  }

  .title {
    @include typography.font_heading_xsmall;

    color: tokens.$color_text_primary;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_heading_standard;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 12px;
    row-gap: 8px;
    justify-content: space-between;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      grid-template-columns: repeat(4, 1fr);
    }

    @media #{ds.$dc-mq-tablet-landscape-and-more} {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  .item:not(.isShow) {
    &:nth-child(n+3) {
      display: none;
    }
  }

  .item {
    width: 100%;

    a {
      width: 100%;
      padding: 0;
      justify-content: flex-start;
    }

    &.isShow {
      display: block;
    }
  }

  .column {
    display: flex;
    width: 100%;
    overflow: hidden;
    flex-direction: column;
    row-gap: 8px;
  }

  .itemButton {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .itemButtonText {
    color: tokens.$color_text_info;
  }

  .icon {
    transition: all 0.2s ease;
    color: tokens.$color_text_info;

    &.isShow {
      transform: rotate(-180deg);
    }
  }
}
