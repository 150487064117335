@use "ds";
@use "tokens";

.container {
  display: flex;
  flex-direction: column;
  padding: 24px 0 16px;
  row-gap: 12px;

  &.isEmpty {
    padding: 24px 16px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      padding: 104px 32px;
    }
  }

  &.isWebView {
    height: 100%;
    border-bottom-left-radius: tokens.$radius_none;
    border-bottom-right-radius: tokens.$radius_none;
    margin-bottom: -2px;
  }

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    row-gap: 0;
    padding: 24px 0 32px;
  }
}
