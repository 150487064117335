@use "ds";
@use "mixins";
@use "tokens";
@use "typography";

.cardContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  column-gap: 16px;
  padding: 16px;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 0.3px solid tokens.$color_border_surface;
  }

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 16px 24px;
  }
}

.cardInner {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: grid;
    grid-template-columns: 250px minmax(220px, 250px) 170px;
    column-gap: 16px;
    align-items: center;
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    display: grid;
    grid-template-columns: minmax(0, 250px) minmax(0, 304px) 170px;
    column-gap: 16px;
    align-items: center;
  }
}

.partnerShip {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.pagination {
  display: flex;
  column-gap: 8px;
  width: fit-content;
  align-items: center;
  margin: 0 auto;
}

.button {
  flex-shrink: 0;
}
