@use "ds";
@use "tokens";
@use "typography";

.agentSpecializations {
  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    grid-column: 1;
  }

  .title {
    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_heading_small;
    }
  }

  .button {
    @include typography.font_body_standard_regular;

    display: flex;
    align-items: center;
    column-gap: 8px;
    color: tokens.$color_text_info;
    margin-top: 16px;
  }
}

.tags {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 8px;
  max-height: 72px;
  overflow: hidden;
  transition: 0.2s ease;
}

.tag {
  color: tokens.$color_text_primary;
}

.tagCount {
  color: tokens.$color_text_secondary;
  margin-left: 4px;
}

.buttonIcon {
  transform: rotate(0deg);
  transition: 0.2s ease;

  &.isShow {
    transform: rotate(180deg);
  }
}
