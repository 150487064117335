@use "ds";
@use "tokens";

.faqItem {
  width: 100%;

  &:not(:last-child) {
    border-bottom: 0.3px solid tokens.$color_border_surface;
  }

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    & > div {
      & > div {
        border-radius: tokens.$radius_none;
      }
    }
  }
}
