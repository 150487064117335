@use "tokens";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;

  &:not(:last-child) {
    border-bottom: 0.3px solid tokens.$color_border_surface;
  }
}

.inner {
  display: flex;
  column-gap: 20px;
}

.avatar {
  width: 56px;
  height: 56px;
  flex-shrink: 0;
  border-radius: tokens.$radius_large;
  overflow: hidden;
}

.textContent {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;
}

.button {
  border-radius: tokens.$radius_medium;
  overflow: hidden;
}
