@use "ds";
@use "tokens";

.agentInfoAvatar {
  grid-column: 1;
  height: 68px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    grid-row: 1 / 3;
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    grid-row: 1 / -1;
  }

  &.isImageEmpty {
    cursor: default;
  }

  [class*="avtr-inner"] {
    background-color: tokens.$color_bg_page;
  }

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    height: 140px;
  }
}
