@use "ds";
@use "mixins";

.agentsListControlsFAQ {
  @include mixins.horizontal-scroll-hidden;

  display: flex;
  column-gap: 8px;
  padding: 0 16px;
  margin-top: 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 0 24px;
    margin-top: 24px;
  }
}
