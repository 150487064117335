@use "tokens";
@use "typography";

.header {
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 16px 0;
  margin-right: 16px;
  margin-left: 16px;

  .headerButton {
    justify-self: flex-end;
  }

  .headerTitle {
    justify-self: center;
  }

  .title {
    @include typography.font_heading_xsmall;
  }

  .icon {
    color: tokens.$color_icon_secondary_default;
  }
}

.container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
