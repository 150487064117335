@use "ds";
@use "tokens";
@use "typography";

.header {
  padding: 0 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 24px;
  }

  .headerTexts {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  .headerDescription {
    color: tokens.$color_text_secondary;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_heading_xsmall;

      color: tokens.$color-text_primary;
    }
  }

  .headerSubtitle {
    display: none;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      color: tokens.$color_text_secondary;
      display: block;
    }
  }

  .headerSort {
    width: 100%;
    max-width: 257px;
  }
}

.headerTags {
  display: flex;
  gap: 8px;
  padding: 0 16px;
}
