@use "ds";
@use "tokens";
@use "mixins";
@use "typography";

.agentInfo {
  display: grid;
  background-color: tokens.$color_bg_surface_primary;
  grid-template-columns: 68px 1fr;
  align-items: center;
  column-gap: 16px;
  padding: 16px;
  border-radius: tokens.$radius_none tokens.$radius_none tokens.$radius_xlarge tokens.$radius_xlarge;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    grid-column: 1;
    grid-template-columns: 140px 1fr;
    align-items: center;
    column-gap: 32px;
    padding: 24px;
    margin-top: 20px;
    border-radius: tokens.$radius_xlarge;
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    margin-top: 0;
    grid-template-rows: repeat(3, auto);
    border-radius: tokens.$radius_xlarge;
  }
}

.progressWrapper {
  margin-top: 16px;
}
