@use "ds";
@use "tokens";

.title {
  display: block;
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 20px;
}

.item {
  display: flex;
  column-gap: 12px;
  color: tokens.$color_icon_success;
}
