@use "ds";
@use "tokens";

.pagination {
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
  background-color: tokens.$color_bg_surface_primary;
  border-bottom-left-radius: tokens.$radius_xlarge;
  border-bottom-right-radius: tokens.$radius_xlarge;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 24px;
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    padding-bottom: 48px;
  }
}
