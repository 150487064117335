@use "ds";
@use "mixins";
@use "typography";
@use "tokens";

.agentInfoMain {
  display: grid;
  row-gap: 12px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    justify-content: space-between;
  }

  .title {
    @include mixins.box;

    grid-area: title;
    width: 100%;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_heading_small;
    }
  }

  .workLocation {
    grid-area: work-location;
    color: tokens.$color_text_secondary;
  }
}

.container {
  display: flex;
  column-gap: 8px;
  row-gap: 6px;

  &.isGrid {
    display: grid;
    grid-template:
      "title rating"
      "work-location work-location"
      / auto 1fr;
  }
}

.rating {
  grid-area: rating;
  align-self: flex-start;
  justify-self: flex-end;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding-top: 2px;
    justify-self: flex-start;
  }
}

.badge {
  height: 24px;
}
