@use "tokens";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;

  &:not(:last-child) {
    border-bottom: 0.3px solid tokens.$color_border_surface;
  }
}

.inner {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.avatar {
  flex-shrink: 0;
}

.textContent {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
}

.feature {
  border-radius: tokens.$radius_large;
  overflow: hidden;
}
