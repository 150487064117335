@use "ds";
@use "tokens";
@use "typography";

.agentDealsStats {
  grid-column: 1;

  .header {
    display: flex;
    justify-content: space-between;
  }

  .title {
    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_heading_small;
    }
  }
}
