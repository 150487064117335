@use "ds";
@use "tokens";
@use "typography";

.agentReviewsEmptyList {
  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    padding: 0 24px;
  }

  .title {
    color: tokens.$color_text_primary;
    display: flex;
    column-gap: 8px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_heading_small;

      align-items: center;
    }
  }

  .description {
    margin-top: 16px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      margin-top: 12px;
    }
  }

  .buttonContainer {
    display: block;
    margin-top: 24px;

    button {
      width: 100%;
      min-height: 48px;

      @media #{ds.$dc-mq-tablet-portrait-and-more} {
        width: auto;
      }
    }
  }
}
