@use "ds";
@use "tokens";
@use "typography";

.faqWhoShowInSearch {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 32px;
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    display: grid;
    grid-template-areas: "picture content";
    grid-template-columns: 447px 1fr;
    column-gap: 32px;
  }

  .title {
    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_promo_small;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    grid-area: content;
  }
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.item {
  display: flex;
  column-gap: 12px;
  color: tokens.$color_icon_success;

  &:last-child {
    color: tokens.$color_icon_critical;
  }
}

.picture {
  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    grid-area: picture;
  }
}
