@use "mixins";

.agentReviewsMobile {
  display: flex;
  flex-direction: column;
  row-gap: 28px;

  .slider {
    @include mixins.horizontal-scroll-hidden;

    width: 100%;
    padding: 0 16px;
    display: flex;
    column-gap: 8px;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  .description {
    padding: 0 16px;
  }
}
