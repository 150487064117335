@use "ds";
@use "tokens";

.container {
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: ds.$dc-z-index-topline - 1;
  background-color: tokens.$color_bg_surface_primary;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    position: static;
    padding: 0;
  }

  &.isWebView {
    padding-bottom: calc(32px + env(safe-area-inset-bottom));
  }
}
