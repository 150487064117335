@use "ds";
@use "tokens";
@use "typography";

.container {
  grid-column: 1 / -1;
  display: flex;
  column-gap: 8px;
  margin-top: 16px;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    background: none;
    grid-column: 2 / 4;
    margin-top: 24px;
    column-gap: 44px;
    align-self: flex-start;
  }

  .item {
    padding: 12px;
    background-color: tokens.$color_bg_surface_secondary;
    border-radius: tokens.$radius_large;
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    width: 100%;

    @media #{ds.$dc-mq-tablet-landscape-and-more} {
      padding: 0;
      max-width: fit-content;
      background: none;
    }
  }

  .title {
    @include typography.font_label_small_regular;

    color: tokens.$color_text_primary;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_label_large_medium;

      color: tokens.$color_text_primary;
    }
  }

  .description {
    @include typography.font_label_small_regular;

    color: tokens.$color_text_secondary;

    &.hasDeals {
      display: none;
    }

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_label_standard_regular;

      color: tokens.$color_text_secondary;

      &.hasDeals {
        display: block;
      }
    }
  }
}
