@use "ds";
@use "tokens";

.agentsListSearchDistrictBlock {
  padding: 16px;
  border-radius: tokens.$radius_none;
  border-bottom: 0.3px solid tokens.$color_border_surface;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 24px 24px 0;
    border-bottom: 0;
  }

  .icon {
    grid-area: icon;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      display: none;
    }
  }
}

.content {
  display: grid;
  align-items: center;
  column-gap: 4px;
  row-gap: 8px;
  grid-template-areas:
    "title icon"
    "modal modal";
  grid-template-columns: 1fr 92px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    grid-template-areas:
      "title images"
      "form  images";
    grid-template-columns: 1fr 311px;
    column-gap: 12px;
    row-gap: 16px;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 0.3px solid tokens.$color_border_surface;
  }
}

.images {
  display: none;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    grid-area: images;
  }
}

.image {
  display: inline-block;
  border-radius: tokens.$radius_full;
  overflow: hidden;
  border: 2px solid tokens.$color_bg_surface_primary;

  &:not(:first-child) {
    margin-right: -20px;
  }
}

.pin {
  color: tokens.$color_icon_secondary_default;

  &.hasError {
    color: tokens.$color_icon_critical;
  }
}

.title {
  grid-area: title;
}

.form {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-area: modal;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    max-width: 699px;
    grid-area: form;
    column-gap: 8px;
  }
}

