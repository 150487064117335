@use "ds";
@use "tokens";

.faqAgentAdvantagesItem {
  display: flex;
  column-gap: 12px;
  align-items: center;
  justify-content: space-between;
  background-color: tokens.$color_bg_surface_tertiary;
  border-radius: tokens.$radius_xlarge;
  padding: 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    width: 356px;
    flex-direction: column-reverse;
    align-items: flex-start;
    row-gap: 24px;
    padding: 24px;
    flex-shrink: 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.image {
  flex-shrink: 0;
}
