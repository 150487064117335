@use "ds";
@use "mixins";
@use "typography";

.faqAgentAdvantages {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px 0;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 32px 0;
    row-gap: 32px;
  }

  .title {
    padding: 0 16px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_promo_small;

      padding: 0 32px;
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 0 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    @include mixins.horizontal-scroll-hidden;

    padding: 0 32px;
    flex-direction: row;
    column-gap: 24px;
  }
}
