@use "tokens";
@use "ds";

.reviewBottomSheetList {
  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-items: center;
  width: 100%;
  padding: 16px 16px 16px 14px;
  background: tokens.$color_bg_surface_primary;
  border-radius: tokens.$radius_small tokens.$radius_small tokens.$radius_none tokens.$radius_none;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding-left: 56px;
    padding-right: 56px;
  }

  .button {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    padding: 4px;
  }
}
