@use "ds";
@use "tokens";
@use "mixins";

.stickyButton {
  position: sticky;
  z-index: 1;
  bottom: 0;
  left: 0;

  &.isWebView {
    margin-top: auto;
    margin-bottom: -2px;
    border-bottom-right-radius: tokens.$radius_none;
    border-bottom-left-radius: tokens.$radius_none;
  }
}

.breadcrumbs {
  @include mixins.default-layout;

  margin-top: auto;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 0 16px;
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    padding: 0;
  }
}
