@use "ds";
@use "mixins";

.breadcrumbs {
  @include mixins.default-layout;

  margin-top: auto;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 0 16px;
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    padding: 0;
  }
}
