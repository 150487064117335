@use "~@front-core/styles-framework" as ds;

@mixin text-overflow() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin default-layout() {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
}

@mixin common-container() {
  @include default-layout();

  margin: 0 0 8px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    margin: 20px auto;
  }
}

@mixin box($line-clamp: 2,   $box-orient: vertical) {
  display: -webkit-box;
  -webkit-line-clamp: $line-clamp;
  -webkit-box-orient: $box-orient;
  overflow: hidden;
}

@mixin horizontal-scroll-hidden() {
  scroll-snap-type: x mandatory;
  overflow-x: auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  overscroll-behavior-x: contain;

  &::-webkit-scrollbar {
    appearance: none;
    display: none;
    width: 0;
    height: 0;
    background: transparent;
    opacity: 0;
  }

  &::-webkit-scrollbar-track {
    display: none;
    background: transparent;
    opacity: 0;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
    background: transparent;
    opacity: 0;
  }
}
