@use "tokens";

.agentsListSearchDistrictBlockModal {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 8px;

  .input {
    width: 100%;
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}

.list {
  overflow-x: auto;
  height: 70vh;

  .emptyList {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    color: tokens.$color_text_secondary;
  }

  .loader {
    display: flex;
    margin-top: 12px;
    justify-content: center;
  }
}
