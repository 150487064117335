@use "ds";
@use "typography";

.faqAppsInstall {
  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: flex;
    column-gap: 24px;
    padding: 32px;
    justify-content: space-between;
  }
}

.container {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}

.title {
  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    @include typography.font_heading_standard;
  }
}

.description {
  margin-top: 8px;
}

.apps {
  margin-top: 20px;
  display: flex;
  column-gap: 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    margin: 0;
    width: fit-content;
  }
}

.qr {
  flex-shrink: 0;
}
