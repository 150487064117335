@use "tokens";

.achievementsTablet {
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  background-color: tokens.$color_bg_page;
  border-radius: tokens.$radius_large;
  margin-top: 12px;

  .title {
    color: tokens.$color_text_primary;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 8px;
  }

  .item {
    width: 100%;
    max-width: 115px;
    height: 69px;
  }

  .button {
    border-radius: tokens.$radius_large;
    overflow: hidden;
    display: block;
    width: 100%;
    height: 100%;

    &:hover {
      img {
        scale: 1.1;
      }
    }

    img,
    picture {
      transition: 0.2s ease;
      display: block;
      width: 100%;
      height: auto;
      border-radius: inherit;
    }
  }
}
