@use "mixins";

.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 16px 0;
}

.titleContainer {
  padding: 0 16px;
}

.title {
  max-width: 88%;
}

.list {
  @include mixins.horizontal-scroll-hidden;

  display: flex;
  column-gap: 8px;
  padding: 0 16px;
}

.item {
  flex-shrink: 0;
  width: 256px;
}

.buttons {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 0 16px;
}
