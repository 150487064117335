.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.title {
  max-width: 59%;
}

.texts {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.textSmall {
  max-width: 59%;
}
