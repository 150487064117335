@use "ds";
@use "tokens";
@use "typography";

.layoutHero {
  display: flex;
  flex-direction: column;

  &.isFullWidthTitle {
    .content {
      margin-top: 16px;
    }
  }

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: grid;
    grid-template-areas: "content picture";
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 1fr;
    column-gap: 32px;
    padding: 32px;

    &.isFullWidthTitle {
      grid-template-areas:
        "title   title"
        "content picture";
      grid-template-columns: repeat(2, 1fr);
      row-gap: 16px;

      .title {
        grid-area: title;
      }

      .content {
        margin-top: 0;
      }
    }
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    grid-template-columns: 1fr 526px;

    &.isFullWidthTitle {
      grid-template-areas:
        "title   picture"
        "content picture";
      grid-template-columns: repeat(2, 1fr);
      row-gap: 24px;

      .title {
        grid-area: title;
      }
    }
  }

  .title {
    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_promo_standard;
    }
  }
}

.content {
  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    grid-area: content;
    margin: 0;
  }
}

.picture {
  margin-top: 20px;
  border-radius: tokens.$radius_xlarge;
  overflow: hidden;
  align-self: center;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    margin: 0;
    grid-area: picture;
    align-self: flex-start;
  }
}
