@use "ds";
@use "tokens";

.agentReviewsList {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  position: relative;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    padding: 0 24px;
  }

  .headerContainer {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    @media #{ds.$dc-mq-tablet-landscape-and-more} {
      display: grid;
      grid-template-columns: 267px 1fr;
      align-items: center;
      column-gap: 16px;
    }
  }

  .checkbox {
    @media #{ds.$dc-mq-tablet-landscape-and-more} {
      justify-self: flex-end;
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    &:not(:last-child) {
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 0.3px solid tokens.$color_bg_control_primary_selected;
    }
  }

  .moreButton {
    display: none;

    @media #{ds.$dc-mq-tablet-landscape-and-more} {
      display: block;
    }
  }
}

.loader {
  width: 100%;
  text-align: center;
  margin: 12px 0;
}
