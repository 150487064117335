@use "ds";
@use "mixins";

.faqAppPage {
  padding-top: 20px;
}

.container {
  @include mixins.default-layout;

  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 20px;
}

.breadcrumbs {
  @include mixins.default-layout;

  margin-top: auto;
  max-width: 1242px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 0 32px;
  }
}
