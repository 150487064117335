@use "tokens";

.reviewsAnswerDropDown {
  [class*="icon-root"] {
    color: tokens.$color_icon_primary_default;
  }

  button {
    background-color: tokens.$color_bg_control_secondary_default;
  }
}
