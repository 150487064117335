@use "mixins";

.container {
  @include mixins.horizontal-scroll-hidden;

  display: flex;
  column-gap: 8px;

  & > div {
    flex: 1 0 300px;
  }
}
