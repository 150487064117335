@use "ds";
@use "tokens";
@use "mixins";

.agentChatQuickForm {
  display: grid;
  row-gap: 12px;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    padding: 0;

    .title {
      margin-bottom: 4px;
    }
  }
}

.infoWrapper {
  display: flex;
  align-items: center;
  column-gap: 16px;
  width: 100%;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    display: none;
  }

  .info {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }

  .infoCompany {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  .infoAvatar {
    position: relative;
    width: 56px;
    height: 56px;
  }

  .company {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .statusIcon {
    margin-top: 4px;
    position: absolute;
    width: 16px;
    height: 16px;
    bottom: 0;
    right: 0;
    background-color: tokens.$color_bg_surface_primary;
    border-radius: tokens.$radius_full;
    color: tokens.$color_text_success;
  }

  .agency {
    color: tokens.$color_text_secondary;
  }

  .roleText {
    @include mixins.box;

    color: tokens.$color_text_secondary;
  }
}

.message {
  display: grid;
  column-gap: 8px;
  grid-template-columns: 1fr auto;
}

.quickMessages {
  & > div {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  button {
    margin: 0;
    padding: 0 10px;
  }
}
