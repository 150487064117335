@use "ds";

.reviewBottomSheetList {
  text-align: center;
  display: grid;
  row-gap: 16px;
}

.buttonContainer {
  display: block;
  margin-top: 56px;
  width: 100%;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    display: none;
  }

  button {
    width: 100%;
    min-height: 48px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      width: auto;
    }
  }
}
