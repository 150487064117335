@use "ds";
@use "mixins";
@use "typography";

.faqChoiceAgent {
  padding: 16px 0;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 32px 0;
  }

  .title {
    padding: 0 16px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_promo_small;

      padding: 0 32px;
    }
  }
}

.items {
  @include mixins.horizontal-scroll-hidden;

  display: flex;
  column-gap: 12px;
  margin-top: 16px;
  padding: 0 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 0 32px;
    margin-top: 32px;
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
}
