@use "ds";
@use "tokens";
@use "mixins";
@use "typography";

.agentsListSearchDistrictModal {
  display: flex;
  align-items: center;
  column-gap: 6px;
  width: fit-content;

  .buttonDistrict {
    text-align: left;
  }

  .buttonText {
    @include mixins.box(1);

    color: tokens.$color_text_info;
    transition: 0.2s ease;

    &:hover {
      color: tokens.$color_component_link_primary_hover;
    }

    &:focus-visible {
      outline: 2px solid;
      border-radius: tokens.$radius_small;
    }

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_heading_xsmall;

      color: tokens.$color_text_info;
    }
  }

  .buttonIcon {
    color: tokens.$color_icon_accent_info;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    transition: 0.2s ease;

    &:hover {
      color: tokens.$color_component_link_primary_hover;
    }

    &:focus-visible {
      outline: 2px solid;
      border-radius: tokens.$radius_small;
    }
  }
}

.pin {
  color: tokens.$color_icon_secondary_default;

  &.hasError {
    color: tokens.$color_icon_critical;
  }
}

.list {
  margin: 8px 0;
  overflow-x: auto;
  height: 70vh;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    height: 230px;

    &::before {
      content: "";
      width: 100%;
      height: 60px;
      position: absolute;
      left: 0;
      bottom: 80px;
      z-index: 1;
      pointer-events: none;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, tokens.$color_bg_surface_primary 100%);
    }
  }

  .emptyList {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    color: tokens.$color_text_secondary;
  }
}

.loader {
  display: flex;
  margin-top: 12px;
  justify-content: center;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;

  .button {
    z-index: 1;
  }
}
