@use "tokens";

.fieldset {
  width: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  overflow: hidden;

  .starIcon {
    color: tokens.$color_component_slider_track_default;
    width: 40px;
    height: 40px;
    padding: 6px;

    &.isError {
      stroke: tokens.$color_border_critical_default;
      color: transparent;
    }
  }
}

.inputs {
  display: flex;
  width: fit-content;
  align-items: center;
  column-gap: 12px;
}

.input {
  width: 40px;
  height: 40px;
  padding: 0;
  margin: 0 -52px 0 0;
  appearance: none;
  outline: none;
  position: relative;
  cursor: pointer;
  z-index: 1;

  &:checked + .starIcon,
  &.isActive + .starIcon,
  &.isHover + .starIcon {
    width: 40px;
    height: 40px;
    color: tokens.$color_bg_accent_warning_contrast;
  }

  &.isActive.isHover ~ &:not(.isHover) + .starIcon {
    color: tokens.$color_component_slider_track_default;
  }
}

