@use "ds";
@use "tokens";

.agencyCallSubstituteModal {
  .description {
    color: tokens.$color_text_secondary;
    margin-top: 8px;
  }

  .position {
    color: tokens.$color_text_secondary;
  }

  .footer {
    margin-top: 16px;
    color: tokens.$color_text_secondary;

    .secondaryText {
      span {
        color: tokens.$color_text_secondary;
      }
    }
  }
}

.header {
  display: none;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: flex;
    column-gap: 16px;
  }
}

.content {
  flex: 1;
}

.qrCode {
  flex-shrink: 0;
  width: 112px;
  height: 112px;
}

.phoneButtons {
  display: flex;
  column-gap: 8px;
  margin-top: 22px;
}

.copyIcon {
  color: tokens.$color_icon_primary_default;
}

.body {
  margin: 0;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    margin-top: 24px;
  }
}

.attention {
  display: flex;
  column-gap: 8px;

  &:not(:first-child) {
    margin-top: 8px;
  }
}

.infoIconSecondary {
  color: tokens.$color_icon_secondary_default;
}

.infoIconWarning {
  color: tokens.$color_icon_warning;
}

.agency {
  display: none;
  column-gap: 8px;
  margin-top: 32px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: flex;
    align-items: center;
  }
}

.avatar {
  flex-shrink: 0;
}

.agencyInfo {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}
