@use "ds";

.container {
  display: grid;
  grid-template-columns: 1fr 56px;
  column-gap: 8px;
  margin-top: 16px;
  padding: 0 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    grid-template-columns: 257px 1fr;
    padding: 0 24px;
  }
}
