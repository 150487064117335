@use "ds";
@use "tokens";
@use "mixins";
@use "typography";

.agencyInfo {
  display: grid;
  background-color: tokens.$color_bg_surface_primary;
  grid-template-columns: 68px 1fr;
  align-items: center;
  column-gap: 16px;
  padding: 16px;
  border-radius: tokens.$radius_none tokens.$radius_none tokens.$radius_xlarge tokens.$radius_xlarge;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    border-radius: tokens.$radius_xlarge;
    grid-template-columns: 140px 1fr auto;
    align-items: center;
    column-gap: 32px;
    padding: 24px;
  }

  .avatar {
    grid-column: 1;
    grid-row: 1 / 3;
    height: 68px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      height: 140px;
    }
  }

  .infoContainer {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    grid-row: 1 / 3;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      grid-row: unset;
    }

    @media #{ds.$dc-mq-tablet-landscape-and-more} {
      grid-column: 2;
      align-self: flex-end;
    }
  }

  .title {
    display: flex;
    column-gap: 8px;
    justify-content: space-between;
    width: 100%;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      justify-content: flex-start;
      align-items: center;
    }

    @media #{ds.$dc-mq-tablet-landscape-and-more} {
      align-items: center;
    }
  }

  .titleText {
    @media #{ds.$dc-mq-tablet-landscape-and-more} {
      @include typography.font_heading_small;
    }
  }

  .subTitle {
    @include typography.font_label_standard_regular;

    color: tokens.$color_text_secondary;
  }

  .rating {
    align-self: flex-start;
    height: 24px;

    @media #{ds.$dc-mq-tablet-landscape-and-more} {
      height: 28px;
    }
  }

  .ratingStar {
    margin-bottom: 4px;
  }

  .company {
    display: flex;
    align-items: center;
    column-gap: 8px;
    text-decoration: none;
  }

  .companyText {
    @include mixins.box;

    color: tokens.$color_text_secondary;
  }

  .status {
    grid-column: 1 / 3;
    margin-top: 16px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      grid-column: 3;
      margin: 0;
      align-self: flex-start;
      max-width: 223px;
    }

    @media #{ds.$dc-mq-tablet-landscape-and-more} {
      transform: translateY(42%);
    }
  }

  .statusIcon {
    flex-shrink: 0;
    align-self: flex-start;
  }

  .statusText {
    color: tokens.$color_text_primary;
  }

  .statusTextDesktop {
    @media #{ds.$dc-mq-tablet-landscape} {
      display: none;
    }
  }

  .progressWrapper {
    margin-top: 16px;
  }
}
