.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.title {
  max-width: 87%;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.item {
  max-width: 62%;
}

.itemSmall {
  flex: 1 0 32%;
}

.text {
  max-width: 30%;
}
