@use "ds";
@use "tokens";

.wrapper {
  grid-column: 1;
  order: 1;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    grid-column: 2;
    grid-row: 1 / -1;
    position: sticky;
    top: 20px;
    left: 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.link {
  span {
    border-bottom: 0;
  }
}

.linkIcon {
  color: tokens.$color_icon_secondary_default;
}
