@use "ds";
@use "tokens";

.container {
  background: tokens.$color_bg_surface_primary;
  border-radius: tokens.$radius_none tokens.$radius_none tokens.$radius_xlarge tokens.$radius_xlarge;
  padding: 16px;
  display: grid;
  grid-template-columns: 68px 1fr;
  align-items: center;
  gap: 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    border-radius: tokens.$radius_xlarge;
    margin: 20px 0;
  }
}

.headerLines {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.headerSmallLine {
  max-width: 42%;
}

.footer {
  grid-column: 1/ -1;
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 8px;
}

.footerContent {
  width: 100%;
  display: flex;
  column-gap: 8px;
}
