@use "ds";
@use "tokens";

.layoutFAQBlock {
  background-color: tokens.$color_bg_surface_tertiary;
  border-radius: tokens.$radius_xlarge;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    display: grid;
    grid-template-areas: "content picture";
    grid-template-columns: 1fr auto;
    column-gap: 32px;
    row-gap: 32px;
    padding: 32px;
    align-items: center;

    &.isReverse {
      grid-template-areas: "picture content";
      grid-template-columns: auto 1fr;
    }
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    grid-template-areas: "content images";
    grid-template-columns: auto 585px;

    &.isReverse {
      grid-template-areas: "images content";
      grid-template-columns: 585px auto;
    }
  }
}

.images {
  display: grid;
  grid-template-columns: 103px auto 1fr;
  width: 100%;
  grid-area: images;

  &.isReverse {
    grid-template-columns: 210px auto 1fr;

    @media #{ds.$dc-mq-tablet-landscape-and-more} {
      .primaryImage {
        grid-column: 1;
        grid-row: 1;
      }

      .secondaryImage {
        grid-column: 3;
        grid-row: 1;
      }
    }
  }
}

.picture {
  border-radius: tokens.$radius_xlarge;
  overflow: hidden;
  margin: 0 auto;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    grid-area: picture;
  }
}

.primaryImage {
  align-self: flex-end;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    grid-column: 3;
    grid-row: 1;
  }
}

.secondaryImage {
  position: relative;
  z-index: 1;
  align-self: center;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    grid-column: 1;
    grid-row: 1;
  }
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    grid-area: content;
    row-gap: 16px;
  }
}

.title {
  white-space: break-spaces;
}

.button {
  width: 100%;
  margin-top: 8px;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    width: fit-content;
  }
}
