@use "ds";
@use "tokens";

.container {
  display: grid;
  background-color: tokens.$color_bg_surface_primary;
  grid-template-columns: 68px 1fr;
  align-items: center;
  column-gap: 16px;
  padding: 16px;
  border-radius: tokens.$radius_none tokens.$radius_none tokens.$radius_xlarge tokens.$radius_xlarge;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    border-radius: tokens.$radius_xlarge;
    grid-template-columns: 140px 1fr;
    align-items: center;
    column-gap: 32px;
    padding: 24px;
  }
}

.avatar {
  grid-column: 1;
  grid-row: 1 / 3;
  height: 140px;
  border-radius: tokens.$radius_2xlarge;
  overflow: hidden;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  width: 100%;
  max-width: 500px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    grid-row: unset;
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    grid-column: 2;
    align-self: flex-end;
  }
}

.progressWrapper {
  margin-top: 16px;
  display: flex;
  column-gap: 32px;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    grid-column: 2;
    align-self: flex-start;
  }
}

.progressItem {
  width: 120px;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}
