@use "ds";
@use "tokens";

.agentsReviewsControls {
  display: grid;
  column-gap: 8px;
  grid-template-columns: 1fr 40px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    grid-template-columns: 238px auto;
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    padding: 0 24px;
  }


  .text {
    color: tokens.$color_text_secondary;
    text-align: left;
    margin-top: 8px;
    grid-column: 1/-1;
  }
}
