@use "tokens";

.appAndroid {
  border-radius: tokens.$radius_medium;
  background-color: tokens.$color_bg_surface_inverse;
  text-align: center;
  width: 100%;
}

.picture {
  background-color: inherit;
  border-radius: inherit;
}
