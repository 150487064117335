.text {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.list {
  list-style-type: disc;
  margin-left: 20px;
}
