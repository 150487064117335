@use "tokens";

.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.title {
  max-width: 39%;
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.item {
  display: grid;
  grid-template-columns: 44px 1fr;
  column-gap: 16px;

  &:not(:last-child) {
    padding-bottom: 24px;
    border-bottom: 0.3px solid tokens.$color_bg_control_primary_selected;
  }
}

.itemContent {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;
}

.itemHeader {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.itemTitle {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;
}

.itemTitleText {
  max-width: 57%;
}

.itemSubTitle {
  max-width: 85%;
}

.itemRating {
  max-width: 57%;
  justify-self: flex-end;
}

.itemTexts {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.itemText {
  max-width: 100%;

  &.isSmall {
    max-width: 85%;
  }
}

.itemButton {
  max-width: 140px;
}
