@use "ds";
@use "tokens";

.container {
  display: flex;
  flex-direction: column;
  padding: 0;

  &.isEmpty {
    padding: 0;
  }

  &.isWebView {
    height: 100%;
    background-color: tokens.$color_bg_surface_primary;

    &.isEmpty {
      padding: 24px 16px;
    }
  }

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 0;
  }
}
