@use "typography";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
  min-height: 80px;
}

.content {
  display: flex;
  flex-direction: column;
  min-height: inherit;
}

.text {
  @include typography.font_label_large_regular;

  flex: 1 0 auto;
}

.button {
  flex: 0 0 auto;
}
