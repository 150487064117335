@use "ds";

.page {
  width: 100%;
  max-width: 1180px;
  margin: 0 0 8px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  &.isWebView {
    flex: 1 0 auto;
  }

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    margin: 0 auto 8px;
  }
}

