@use "ds";
@use "tokens";

.container {
  display: flex;
  flex-direction: column;
  background-color: tokens.$color_bg_surface_primary;
  row-gap: 12px;
  border-bottom-right-radius: tokens.$radius_xlarge;
  border-bottom-left-radius: tokens.$radius_xlarge;
  overflow: hidden;
  padding-bottom: 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    row-gap: 16px;
    padding-bottom: 24px;
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    padding-bottom: 48px;
  }
}

.pagination {
  display: flex;
  column-gap: 8px;
  width: fit-content;
  align-items: center;
  margin: 0 auto;
}
