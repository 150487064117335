@use "ds";
@use "tokens";
@use "typography";

.agentAgency {
  display: grid;
  row-gap: 20px;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    row-gap: 16px;
  }

  .description {
    color: tokens.$color_text_secondary;
  }

  .title {
    @include typography.font_heading_small;

    color: tokens.$color_text_info;
    transition: 0.2s;

    &:hover {
      color: tokens.$color_component_link_primary_hover;
    }
  }
}

.topContent {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 68px;
  gap: 12px;
  grid-template-areas:
    "header avatar"
    "info   avatar";

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    grid-template-columns: 1fr 140px;
    align-items: center;
    grid-template-areas:
      "header avatar"
      "info   avatar";
  }
}

.header {
  grid-area: header;
  display: grid;
  row-gap: 6px;
}

.primaryInfo {
  display: flex;
  column-gap: 8px;
}

.info {
  grid-area: info;
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    flex-direction: row;
    column-gap: 44px;
  }
}

.avatar {
  grid-area: avatar;
}

.bottomContent {
  display: grid;
  row-gap: 8px;
}
