@use "ds";
@use "tokens";

.agentsListControlsFAQItem {
  width: 230px;
  flex-shrink: 0;
  background-color: tokens.$color_bg_surface_secondary;
  border-radius: tokens.$radius_xlarge;
  padding: 16px;
  display: grid;
  row-gap: 12px;
  grid-template:
    "title title"
    "button icon" / 1fr 60px;
  overflow: hidden;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    width: 372px;
    padding: 24px;
    grid-template:
      "title icon"
      "button icon" / 1fr 100px;
  }
}

.title {
  grid-area: title;
  white-space: break-spaces;
}

.button {
  grid-area: button;
  align-self: flex-end;
}

.icon {
  grid-area: icon;
  width: 60px;
  height: 60px;
  margin-bottom: -16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    justify-self: flex-end;
    width: 100px;
    height: 100px;
    margin-bottom: -24px;
    transform: translateY(24px);
  }
}
