@use "ds";
@use "tokens";

.container {
  padding: 20px 24px 0;
  position: relative;
  background-color: tokens.$color_bg_surface_primary;

  &:not(:last-child) {
    .inner {
      border-bottom: 0.3px solid tokens.$color_border_surface;
    }
  }
}

.inner {
  padding-bottom: 24px;
  display: grid;
  column-gap: 32px;
  align-items: center;
  grid-template-columns: 68px 1fr;
}

.content {
  display: grid;
  align-items: center;
  column-gap: 20px;
  grid-template-columns: minmax(247px, 358px) minmax(130px, 180px) minmax(52px, 106px) minmax(85px, 106px) 158px;

  &.isDistrict {
    grid-template-columns: minmax(247px, 376px) minmax(160px, 296px) minmax(85px, 106px) 158px;
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    column-gap: 32px;
    grid-template-columns: 280px minmax(268px, 452px) 50px 90px 158px;

    &.isDistrict {
      grid-template-columns: 280px minmax(268px, 506px) 90px 158px;
    }
  }
}

.info,
.rating {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.features {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.feature {
  border-radius: tokens.$radius_medium;
  overflow: hidden;
}
