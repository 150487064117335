@use "ds";
@use "mixins";
@use "tokens";

.container {
  @include mixins.common-container;

  & > div {
    border-top-right-radius: tokens.$radius_none;
    border-top-left-radius: tokens.$radius_none;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    & > div {
      border-radius: tokens.$radius_2xlarge;
      padding-top: 104px;
      padding-bottom: 104px;
    }
  }
}
