@use "ds";
@use "typography";

.faqClientAttention {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 32px;
    row-gap: 32px;
  }

  .title {
    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_promo_small;
    }
  }
}

.list {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    row-gap: 24px;
  }
}

