@use "ds";

.agentReviewsListSkeleton {
  display: flex;
  flex-direction: column;
  row-gap: 28px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    row-gap: 0;
  }
}

