@use "ds";
@use "tokens";
@use "typography";

.layoutBox {
  display: grid;
  row-gap: 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    grid-template-areas:
      "title   image"
      "content image";
    grid-template-columns: auto 342px;
    column-gap: 20px;
    row-gap: 32px;
    padding: 32px;

    .title {
      @include typography.font_promo_small;

      grid-area: title;
    }
  }

  @media #{ds.$dc-mq-desktop-and-more} {
    grid-template-columns: auto 548px;
  }
}

.content {
  display: flex;
  flex-direction: column;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    grid-area: content;
  }
}

.image {
  border-radius: tokens.$radius_large;
  overflow: hidden;
  justify-self: center;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    grid-area: image;
    justify-self: unset;
  }
}
