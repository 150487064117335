@use "ds";

.faqAgentCooperation {
  padding: 16px;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    padding: 32px;
  }

  @media #{ds.$dc-mq-desktop-and-more} {
    padding: 0 32px;

    .title {
      padding-top: 32px;
    }
  }
}

.content {
  @media #{ds.$dc-mq-desktop-and-more} {
    padding-bottom: 32px;
  }
}


.button {
  margin-top: auto;

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    width: fit-content;
  }
}
