.container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.title {
  max-width: 39%;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.item {
  max-width: 23.5%;

  &.isSmall {
    max-width: 21%;
  }
}
