@use "ds";
@use "tokens";
@use "typography";

.container {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  .title {
    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      @include typography.font_heading_small;
    }
  }
}
