@use "ds";
@use "mixins";

.container {
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  align-items: flex-start;
  row-gap: 8px;
  width: 100%;
  max-width: 1180px;
  margin: 0;

  &.isWebView {
    height: 100%;
  }

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    margin: 20px auto 0;

    &:last-child {
      margin: 20px auto;
    }
  }

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    grid-template-columns: minmax(0, 812px) 317px;
    column-gap: 8px;
  }

  @media #{ds.$dc-mq-desktop-and-more} {
    grid-template-columns: minmax(0, 812px) 380px;
  }
}

.mainContainer {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  height: 100%;
}

.aside {
  display: none;

  @media #{ds.$dc-mq-tablet-landscape-and-more} {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    position: sticky;
    top: 20px;
    left: 0;
  }
}
