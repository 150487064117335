@use "ds";

.faqAppHero {
  &[class^="box"] {
    padding: 16px 16px 0;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      padding: 32px 0 0 32px;
    }

    @media #{ds.$dc-mq-tablet-landscape-and-more} {
      padding: 32px 32px 0;
    }
  }
}

.text {
  margin-top: 16px;
}

.image {
  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    align-self: flex-end;
    justify-self: flex-end;
  }
}

.apps {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  margin-top: 20px;
  width: 100%;

  img {
    width: 100%;
  }

  @media #{ds.$dc-mq-tablet-portrait-and-more} {
    grid-template-columns: repeat(2, calc(50% - 8px));
    margin-top: 32px;
    max-width: 314px;
    padding-bottom: 32px;
  }
}
