@use "ds";
@use "tokens";
@use "mixins";

.form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 0;
  margin: 0 0 4px;

  .userContainer {
    display: flex;
    column-gap: 12px;
  }

  .userInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 4px;
  }

  .userTitle {
    display: flex;
    width: 100%;
    column-gap: 8px;
    justify-content: space-between;
    color: tokens.$color_icon_success;
    margin-bottom: 2px;
  }

  .userTitleText {
    @include mixins.box(2);
  }

  .iconSuccess {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
  }

  .agencies {
    @include mixins.box(2);

    color: tokens.$color_text_secondary;
  }

  .secondaryText {
    display: block;
    color: tokens.$color_text_secondary;
  }

  .buttons {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media #{ds.$dc-mq-tablet-portrait-and-more} {
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}
